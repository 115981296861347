// import { Link } from "gatsby"
import React from "react";
import Repos from "../components/Repos";

export default function Home() {
  return (
    <div>
      <header className="p-4 md:py-8 lg:px-0 bg-green-700 text-green-100 shadow-md">
        <div className="flex lg:max-w-5xl mx-auto">
          <div className="flex flex-col border-b">
            <h1 className="text-2xl md:text-3xl">Kevin J. Estevez</h1>
            <h3 className="text-sm md:text-xs self-end">Software Engineer</h3>
          </div>
          <nav className="flex-1">
            {/* <Link className="">Projects</Link> */}
          </nav>
        </div>
      </header>
      <main className="bg-gray-50 max-w-full mx-auto py-6 sm:px-6 lg:px-8">
        <Repos />
        {/* <div class="px-4 py-6 sm:px-0">
          <div class="border-4 border-dashed border-gray-200 rounded-lg h-96"></div>
        </div> */}
      </main>
    </div>
  );
}
