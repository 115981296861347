import React from "react";
import reposJson from "../data/repos.json";

const Repos = () => {
  const repoNames = [
    "AboutLibGDX",
    "RacerGear",
    "AccessMatrix",
    "KenyToken",
    /*'calendar-challenge',*/ "bottle-challenge-bot",
    /*'pixel-art-generator',*/ "CStoJS_Compiler",
    "Automaton",
    "ChessChaturanga" /*'FallingWords', 'SEMITA'*/,
  ];

  const repos = repoNames.map(repoName =>
    reposJson.find(repo => repo.name === repoName)
  );
  // .filter(repo => !(repo.name.includes('Examen') || repo.fork));
  return (
    <section className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <h2 className="px-4 sm:px-0 font-semibold text-3xl text-green-800">
        Repositories
      </h2>
      <div className="flex-col sm:grid sm:grid-cols-3 xl:grid-cols-4 gap-4">
        {repos.map(repo => (
          <div key={repo.full_name} class="px-4 py-6 sm:px-0">
            <div class="box-border border-4 border-dashed border-t-0 bg-white border-gray-200 rounded-lg h-56 flex flex-col">
              <h6 className="p-1 text-center rounded-b-full bg-green-200 text-gray-900">
                {repo.name}
              </h6>
              <p className="inline-block flex-1 py-3 px-4 max-h-28 text-md overflow-y-hidden">
                {repo.description}
              </p>
              <div className="flex justify-between p-2">
                <a
                  href={repo.html_url}
                  className="btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See
                </a>
                {repo.default_branch === "gh-pages" && (
                  <a
                    href={`https://${repo.owner.login}.github.io/${repo.name}`}
                    className="btn ml-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View site
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Repos;
